@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

:root {
    --maincolor: #C7D121;
    --secondcolor:#7B8F1C ;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'roboto', sans-serif !important;
    font-weight: 400; 
}
.app{
  position: relative;
  background-color: white;
}

/* Algemeen */
.no{
  text-decoration: none;
  color: white;
  display: flex;
  align-items: center;
}
.samen{
  display: flex;
  align-items: center;
}
.samen svg{
  margin-right: 5px;
}
.underline{
  text-decoration: none;
  color: white;
}
.top{
  margin-top: 30px;
}
.bottom{
  margin-bottom: 30px;
}
.link{
  text-decoration: underline;
  color: var(--secondcolor);
  
}
.bold{
  font-weight: 700;

}
.sm-top{
  margin-top: 5px;
}
.pad{
  padding: 5px 10px;
  margin-top: 5px;
}
/* Navigation */
nav{
  display: flex;
  z-index: 1 !important;
  position: sticky;

}
.nav-img{
  width: 200px;
  height: 25vh;
  background-color: white;
  padding: 20px;
  z-index: 1 !important;
}
.nav-logo{
  object-fit: contain;
  width: 100%;
  height: 100%;
  z-index: 1 !important;
}
.nav-bar{
  background-color: #F2F2F2;
  width: 100%;
  height: 7vh;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 0 !important;
}
.nav-bar-list{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5vh;
  list-style: none;
  height: 100%;
}
.nav-bar-left{
  display: flex;
  height: 100%;
  align-items: center;
}
.nav-bar-left li{
  height: 100%;
  display: flex;
  align-items: center;
}
.nav-bar-left li a{
  color: black !important;
  height: 100%;
  display: flex;
  align-items: center;
}
.activeNav{
  border-bottom: 3px solid var(--secondcolor);
  background-color: #e7e7e7;
}
.nav-bar-right{
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}
.nav-bar-right li a{
  color: white !important;
}


.nav-bar-list li{
  padding: 0 20px;
}
.nav-bar-list li a{
  text-decoration: none;
  color: black;
  font-weight: 700;
}



/* Header */
header{
  position: absolute;
  top:0 !important;
  left: 0;
  z-index: 0;
  width: 100%;
}
.img-header{
  width: 100%;
  height: 50vh;
  object-fit: cover;
  
}
.header-block{
  display: flex;
  align-items: center;
  position: absolute;
  top : 45vh;
  width: 100%;
}
.header-flag{
  width: 50vw;
  height: 100px;
  background-color:white;
  box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-title{
  font-weight: 700;
}
.header-subflag{
  background-color: #F2F2F2;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left:  5vw;
}
.header-subtitle{
  color : #9A9A9A;
}


/* Footer */
footer{
  display: flex;
  flex-direction: column;
  
}

.upper-footer{
  padding : 10vh 10vw;
  display: flex;
}
.upper-footer ul{
  list-style: none;
  margin-right: 5vw;
}
.upper-footer ul li{
  margin-bottom: 10px;
}
.upper-footer ul li a{
  text-decoration: none;
  color: black;
}
.socials{
  display: flex;
  align-items: center;
  justify-content: center;
}
.socials a{
  background-color: black;
  color: white;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border-radius: 50%;

}
.social-icon{
  color : white;
}

.down-footer{
  background-color: black;
  color: white;
  padding : 10vh 10vw;
  display: flex;
  justify-content: space-between;
}

.down-footer-left{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border-radius: 50%;
}
.down-footer-info{
  display: flex;
}
.down-footer-info-img{
 height: 100%;
  width: 50px;
  object-fit: cover;
  object-position: center;
  margin-right: 10px;
}
.down-footer-info-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.down-footer-right{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  border-radius: 50%;
}

.down-footer-right img{
  height: 50px;
  margin-right: 15px;
}
.dev-by{
  background-color: black;
  color: white;
  padding: 0 10vw;
  padding-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.devlink{
  color: white;
  text-decoration: none;
  font-weight: 700;
  margin-left: 5px;
}

  /* Main */
main{
  padding-top: 40vh;
  padding-bottom: 10vh;
  padding-left: 10vw;
  padding-right: 10vw;
  min-height: fit-content !important;
}

/* News */

.news-item-link{
  text-decoration: none;
  color: black;
}

/* Latest news */
.big-block{
  min-height: fit-content;
  display: flex;
  margin-bottom: 10vh;
  position: relative;
}
.big-block-left{
  width: 50%;
  min-height: 50vh;
  position: relative;
}
.big-block-info{
  margin-top: 30px;
}
.big-block-title{
  font-size: 2rem;
  font-weight: 700;
  margin-bottom: 15px;
}
.big-block-text{
  margin-bottom: 15px;
}
.big-block-btn{
  margin-top:  35px;
  background-color: black;
  color: white;
  padding: 15px 50px; 
  width: 250px;
  text-align: center;
  bottom: 0px;
  transition: 0.5s;
}
a {
  text-decoration: none !important;
  color: var(--black) !important;
  font-weight: 700 !important;
}
.big-block-btn:hover{
  background-color: var(--secondcolor);
  color: white;
  cursor: pointer;
}
.label-small{
  color : var(--secondcolor);
  font-weight: 700;
}
.big-block-right{
  margin-left: 10vw;
  /* background-color: #9A9A9A; */
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
  position: relative;
  /* box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; */
}
.big-block-img{
  max-width: 100%;
  max-height: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  position:absolute;
  top: 7vh;
}

.nota{
  position: relative !important;
  top: 0 !important;
}


.news-item-wrapper{
  display: flex;
  margin-top: 30px;
  overflow-x: scroll;
  position: absolute;
  width: 88.9vw;
  
}



.relative-container{
  position: relative;

}


/* News Item */
.news-item{
  display: flex;
  margin-bottom: 5vh;
  position: relative;
  min-width: 20vw;
  margin-right: 5vw;
  flex-direction: column;
}
.news-item-top{
  height: 20vh;
  width: 100%;
  background-color: #505050;
}
.news-item-bottom{
  height: 30vh;
  width: 100%;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
}
.news-item-title{
  font-size: 1.5rem;
  font-weight: 700;
}
.news-item-date{
  margin-bottom: 15px;

}
.news-item-text{
  margin-bottom: 15px;
}
.news-item-btn{
  position: absolute;
  bottom: 15px;
  border-bottom: 3px solid var(--secondcolor);
}
.news-item-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; 
}
.nolinkstyle{
  text-decoration: none;
  color: black;
}
/* Customize the horizontal scrollbar for .news-item-wrapper */
.news-item-wrapper::-webkit-scrollbar {
  width: 2px; /* Width of the scrollbar */
  height: 10px;
}

.news-item-wrapper::-webkit-scrollbar-track {
  background-color: transparent; /* Track color */
}

.news-item-wrapper::-webkit-scrollbar-thumb {
  background-color: var(--secondcolor); /* Thumb color */
  border-radius: 0px; /* Round corners of the thumb */
}

.news-item-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Thumb color on hover */
}

/* Loader */
.loader {
  
 
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid var(--maincolor);
  border-radius: 50%;
  animation: spin 1s linear infinite;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  z-index: 1001;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}




/* Back section */
.back-section{
  background-color: var(--maincolor);
  width: 100%;
  height: 50vh;
}

/* Contact section */
.contact-section{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10vh;
  padding-bottom: 10vh;
}

/* 404 */
.viernulvier{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: 10vh;
  padding-bottom: 10vh; 
}

/* Takken */
.takken-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5vw;

}

.info-page{
  width: 40%;
  margin-top: 15px;
}
.info-page-tak{
  width: 50%;
}

.container-upper-tak{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.image-tak{

 

  min-width: none;
  position: relative;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}
.image-tak img{
  width: 100%;
  height: 100%;
  object-position: center;
  object-fit: cover;
}

.leiding-container{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5vw;
  margin-top: 30px;
  
}

.leiding-item{
  display: flex;
  flex-direction: column;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
  height: 40vh;
}
.leiding-info{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50%;
  width: 100%;
}
.leiding-img{
  height: 60%;
  width: 100%;
  object-fit: cover;
  object-position: center;
} 
/* first child of leiding-container */
.leiding-item:first-child .leiding-info{
  padding-top: 15px;
  
}
.leiding-bottom-info{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  border-top : 1px solid #9a9a9a52;
  width: 90%;
  padding-top: 15px;
  padding-bottom: 15px;
}
.leiding-icon{
  margin-right: 10px;
  color: var(--secondcolor);

}

.tak{
  display: flex;
  margin-bottom: 5vh;
  /* box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; */
  transition: 0.5s;
  padding : 15px;
}
.tak:hover{
  cursor: pointer;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.tak-img{
  height: 20vh;
  width: 13vh;
  object-fit: cover;
  object-position: center;
  margin-right: 2vw;
  /* box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; */
}
.tak-img-b{
  height: 30vh;
  width: 18vh;
  object-fit: cover;
  object-position: center;
  margin-right: 2vw;
  /* box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; */

}
.tak-info{
  padding-top: 15px;
  display: flex;
  flex-direction: column;
}
.tak-title{
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 15px;
}


/* About */
.about-block{
  display: flex;
}
.about-left-block{
  width: 50%;
  height: fit-content;
  position: relative;
}
.about-info-block{
  margin-top: 30px;
}
.about-right-block{
  margin-left: 10vw;
  background-color: #9A9A9A;
  width: 50%;
  height: 50vh;
  position: relative;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}
.about-right-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}


/* Contact */
form{
  display: flex;
  flex-direction: column;
  margin-top: 5vh
}
.rijForm{
  display: flex; 
  width: 100%;
  justify-content: space-between;
}
.colomForm{
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 20px;
 
  margin-bottom: 20px;
}
.inputlabel{
  font-weight: 700;
}
.inputField{
  width: 100%;
  height: 5vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding-left: 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  transition: 0.5s;
  outline: none;
}
.rijFormFull{
  width: 100%;
  padding-right: 20px;
}
.inputFieldBig{
  width: 100%;
  height: 25vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding : 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  resize: none;
  transition: 0.5s;
  outline: none;
}
.inputFieldcat{
  width: 100%;
  height: 5vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding-left: 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  transition: 0.5s;
  outline: none;
}
.btnadd{
  background-color: var(--maincolor);
  border-radius: 5px;
  color: var(--white) !important;
  padding: 1em 5em;
  font-weight: 700;
  border : 0;
  cursor: pointer;
  transition: 0.5s;
  margin-top: 20px;
  
}
.inputFieldBig:focus, .inputField:focus{
  
  border : 1px solid var(--maincolor);
}

.green{
  margin-top: 5px;
  color: var(--secondcolor);
}
.red{
  margin-top: 5px;
  color: red;
}


/* Geschiedenis */
.kentekens-block{
  margin-top: 10vh;
  position: relative;
}
.kentekens-container{
  display :grid ;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 2vw;
  margin-top:30px;

}
.history-right-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.history-right-block{
  margin-left: 10vw;
  background-color: #9A9A9A;
  width: 50%;
  height: 60vh;
  position: relative;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.kenteken{
  text-decoration: none;
  display: flex;
  margin-bottom: 5vh;
  position: relative;
  width: 100%;
  margin-right: 5vw;
  flex-direction: column;
}
.kenteken-img{
  width: 100%;
  height: 80%;
}
.kenteken-img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px; 
}
.kenteken-info{
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  color: black;
}
.kenteken-title{
  font-weight: 700;
  margin-bottom: 15px;
}

.big-block-right-kenteken{
  margin-top: 7vh;
  margin-left: 15vw;
  background-color: #9A9A9A;
  width: 28%;
  height: 100%;
  position: relative;
  box-shadow: rgba(50, 50, 105, 0.15) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px;
}

.aangeduid{
  /* color: var(--secondcolor); */
  font-weight: 700;
}


/* Admin */
.admin-nav{
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}
.admin-nav-item{
  margin-right: 10px;
  background-color: #F2F2F2;
  padding : 20px;
  display: flex;
  align-items: center;
  border-radius: 10px;
}
.admin-nav-item:hover{
  cursor: pointer;
}
.admin-nav-item svg{
  color: #9A9A9A;
}
.activeAdmin{
  background-color: black;
  transition: 0.5s;
}
.activeAdmin svg{
  color: white;
}

.leiding-list {
  background-color: #F2F2F2;
  height: fit-content;
  width: 100%;
  border-collapse: collapse;
  margin-top:  20px;
}
thead{
  background-color: black;
}
.leiding-list thead th{
  color: white;
  padding: 10px 20px;
}
.leiding-list th{
  font-weight: 700;
  padding-bottom: 10px;
  text-align: start;
}

.leiding-list td{
 
  padding: 10px 20px;
  
}

.leiding-list td:first-child{
  width: 100px;
}

.row-even{
  background-color: #ffffff;
}
.row-odd{
  background-color: #F2F2F2;
}


.functieSelect{
  width: 100%;
  height: 5vh;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #9a9a9a53;
  padding-left: 1em;
  font-size: 1em;
  font-weight: 400;
  transition: 0.5s;
  outline: none;
}
.imgTable{
  width: 50px;
  height: 50px;
  object-fit: cover;
  object-position: center;
  border-radius: 10px;
}
.imgTable-kenteken{
  width: 30px;
  height: 50px;
  object-fit: cover;
  object-position: center;
}
.imgTable-kenteken-tak{
  width: 50px;
  height: 100%;
  object-fit: cover;
  object-position: center;

}

.searchbar{
  height: 5vh;
  border-radius: 5px;
  border : 1px solid #9a9a9a53;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  transition: 0.5s;
  outline: none;
  width: 20vw ;
  min-width: fit-content;
  display: flex;
  align-items: center;
}
.searchbar-input{
  border: 0;
  background-color: transparent;
  width: 100%;
  height: 100%;
  outline: none;
  margin-left: 1em;
}
.searchbar svg{
  color : #9A9A9A;
}

.upperPart-admin{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-save{
  background-color: var(--maincolor);
  border-radius: 5px;
  color: var(--white) !important;
  height: 3em;
  padding : 0 5em;
  font-weight: 700;
  border : 0;
  text-decoration: none;
  align-items: center;
  display: flex;
  cursor: pointer;
  transition: 0.5s;
}
.action-table{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.action-table svg{
  margin-right: 20px;
  transition: 0.5s;
}
.deletetrash{
  color : red;
  cursor: pointer;
}
.editpencil{
  color : orange;
}
.addpage{
  padding-left: 10vw;
  padding-right: 10vw;
  min-height: fit-content;
}
form input[type="file"] {
  
  display: none;
}
.upload-f{
  display: none;
}

.boekje-link{
  color: var(--secondcolor);
}

.custom-file-upload-t{
  border: 1px solid #ccc;
  cursor: pointer;
  width:100%;
  height: 5vh;
  border-radius: 5px;
  background-color: var(--maincolor);
  border: 0;
  font-size: 1em;
  font-weight: 400;
  transition: 0.5s;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin : 0.5em 0em;
  
}
.custom-file-upload-t:hover{
  cursor: pointer;
  background-color: #ccc;
}
.custom-file-upload-t svg{
  margin-right: 0.5em;
  color : var(--secondcolor)
}

.custom-file-upload {
  border: 1px solid #ccc;
  cursor: pointer;
  width: 100%;
  height: 5vh;
  border-radius: 5px;
  background-color: #f2f2f2;
  border: 0;
  padding-left: 1em;
  margin-top: 1em;
  font-size: 1em;
  font-weight: 400;
  transition: 0.5s;
  outline: none;
  display: flex;
  align-items: center;
}
.custom-file-upload:hover{
  cursor: pointer;
  background-color: var(--maincolor);
}
.custom-file-upload svg{
  margin-right: 0.5em;
  color : var(--secondcolor)
}
.btn-back{
  color: black;
}
.takkenColum{
  display: flex;
}
.takRow{
  display: flex;
  align-items: center;
  
  font-weight: 700;
  position: relative;
  padding: 10px 10px 10px 15px;
  border-radius: 5px 0 0 5px;
  font-size:  0.8rem; 
  border: 0;
}


.Kapoenen{
  background-color: #DCEF07;
  color : black;
}
.Welpen{
  background-color: #4EBDB6;
  color : white;

}
.Pioniers{
  background-color: #296B07;
  color : white;

}
.Jongverkenners{
  background-color: #FB8B05; 
  color : white;
}
.Verkenners{
  background-color: #0D29D3;
  color : white; 
}
.Jin{
  background-color: #B1031C; 
  color : white;
}
.Takleiding{
  background-color: #730CCD;
  color : white;
}
.Groepsleiding{
  background-color: #542D00;
  color : white;
}
.Mastworp{
  background-color: #D94B38;
  color : white;
}
.addTak{
  background-color: #F2F2F2;
  color : black;
  padding : 10px;
  border-radius: 5px;
  transition: 0.5s;
  cursor: pointer;
  margin-left: 5px;
}

.holeselect{
 display: flex;
 margin-right: 15px;
}

.uppericonselect{
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-left: 1px solid #9a9a9a53;
  transition: 0.5s;
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}
.exampleimage{
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 5px ;
}

.corners{
  border-radius: 0 5px 5px 0;
  height: 100%;
  width: 20px;
}


.nav-toggle{
  display: none;
}
/* Phone */

@media (max-width: 768px) {

  *{
    font-family:  'Inter', sans-serif !important;
  }
  nav{
    position: relative;
    background-color: white;
  }
  .nav-bar{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 5vw;
    background-color: white;
  }
  .nav-toggle{
    display: flex;
    flex-direction: column;
  
  }
  .nav-toggle .bar{
    width: 20px;
    height: 2px;
    background-color: black;
    margin: 3px;
  }

  .nav-toggle-open .bar{
    width: 20px;
    height: 2px;
    background-color: black;
    margin: 3px;
    
  }

  .nav-menu-mobile{
    position: absolute;
    top: 7vh;
    background-color: white;
    width: 100%;
  }
  .nav-menu-mobile ul{
    list-style: none;
    padding: 0;
  }
  .nav-menu-mobile ul a li{
    padding: 15px 20px;
    border-bottom: 1px solid #ccc;
  }
  .nav-menu-mobile ul a{
    text-decoration: none;
    width: 100%;
    color: black;
  }

  .nav-img{
    left: 0;
    position: relative;
    height: 7vh;
    padding: 0px 5vw;
    background-color: white;
    display: flex;
    align-items: center;
    
  }
  .nav-img img{
    position: absolute;
    left: -25px !important;
    width: 80%;
    height: 80%;
  }
  .nav-bar ul{
    display: none;
  }

  .header-block{
    top: 40vh;
    display: flex;
    flex-direction: column;
  }
  header{
    position: relative;

  }
  .header-flag{
    width: 100%;
    height: 70px;
  }
  .header-title{
    font-weight: 700 !important;
    font-size: 1.5rem;
  }
  main{
    
    margin-top: 20%;
    padding-top: 0;
    padding-bottom: 5vh;
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .down-footer{
    padding : 5vh 5vw;
    display: flex;
    flex-direction: column;
  }
  .down-footer-right{
    margin-top: 5vh;
    margin-right: 0px;
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  .down-footer-info{
    display: flex;
    flex-direction: column;
    align-items: self-start;
    
  }
    .down-footer-info-img{
        width: 20%;
        margin-right: 0px;
    }
    .down-footer-info-text{
        margin-top: 2vh;
    }
  .big-block{
    display: flex;
    flex-direction: column;
  }
  .big-block-left{
    width: 100%;
  }
  .big-block-right{
    margin-top: 5vh;
    width: 100%;
    height: max-content;
    margin-left: 0;
  }

  .big-block-img{
    position: relative;
    top:0;
  }

  .big-block-btn{
    position: relative;
    bottom: 0px;
    width: 100%;
    margin-top: 5vh;
  }

  .news-item-wrapper{
    width: 100%;
  }
  .news-item-link{
    min-width: 250px;
    margin-right: 5vw;

  }
  .news-item{
    min-width: 100%;
    margin-right: 0;
    
  }
  .back-section{
    height: 60vh;
  }

  .upper-footer{
    padding : 5vh 5vw;
    display: flex;
    flex-direction: column;
  }

  .upper-footer ul li{
    padding : 15px 0;
    margin-bottom: 0;
    border-bottom: 1px solid #ccc;
    }

  .socials{
    justify-content: start;
  }

  .info-page{
    width: 100%;
  }

  .takken-container{
    grid-template-columns: repeat(1, 1fr);
  }

  .about-block{
    display: flex;
    flex-direction: column;
  }
  .about-left-block{
    width: 100%;
    height: auto;
  }
  .about-right-block{
    margin-top: 5vh;
    width: 100%;
    margin-left: 0;
    height: auto;
  }

  .history-right-block{
    margin-top: 5vh;
    width: 100%;
    margin-left: 0;
    height: auto;
  }

  .kentekens-container{
    grid-template-columns: repeat(2, 1fr);
  }

  .rijForm{
    flex-direction: column;
  }
  .rijFormFull{
    padding-right: 0px;
  }
.btnadd{
  width: 100%;
}

.container-upper-tak{
  flex-direction: column;
  align-items: self-start;
}
.info-page-tak{
  width: 100%;
}
.tak-img-b{
  display: none;
}
.leiding-container{
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 5vh;
  margin-top: 0;
}
.admin-nav{
  justify-content: space-between;
}
.admin-nav-item{
  padding : 25px;
}
.upperPart-admin{
  flex-direction: column;
}
.searchbar{
  width: 100%;

}
.btn-save{
  width: 100%;
  padding: 0;
  margin-top: 2vh;
  align-items: center;
  justify-content: center;
}


.table-container{
  overflow-x: scroll;
  width: 100%;
}
.leiding-list {
  width: 100%;
  border-collapse: collapse;
}
.leiding-list td{
  padding: 10px 10px;
}

.big-block-right-kenteken{
  margin-left: 0;
  width: 100%;
  height: auto;
  margin-top: 5vh;
}

}
 